/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-tdu5bp js-anim  --anim3 --full pb--10" anim={"3"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/28a9486d469441889ebc749686255e62_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/28a9486d469441889ebc749686255e62_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/28a9486d469441889ebc749686255e62_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/28a9486d469441889ebc749686255e62_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/28a9486d469441889ebc749686255e62_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/28a9486d469441889ebc749686255e62_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/28a9486d469441889ebc749686255e62_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/28a9486d469441889ebc749686255e62_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column className="pb--08 pt--60" name={"sketch"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--48" content={"marek tůma.<br><span style=\"font-style: italic;\">the sketch artist.</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":490}} content={"In cooperation with pencil from 1995<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--06 pt--06" name={"qk1ry6uerhq"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/af85cd8ed9d348fd86b191be50c72f4a_s=860x_.JPG"} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/19495/af85cd8ed9d348fd86b191be50c72f4a_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19495/af85cd8ed9d348fd86b191be50c72f4a_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19495/af85cd8ed9d348fd86b191be50c72f4a_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19495/af85cd8ed9d348fd86b191be50c72f4a_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19495/af85cd8ed9d348fd86b191be50c72f4a_s=2000x_.JPG 2000w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/92047fe1cbef40aca9498c10d5731e82_s=860x_.JPG"} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/19495/92047fe1cbef40aca9498c10d5731e82_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19495/92047fe1cbef40aca9498c10d5731e82_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19495/92047fe1cbef40aca9498c10d5731e82_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19495/92047fe1cbef40aca9498c10d5731e82_s=1400x_.JPG 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/00696c8119524e6fb05f7acc70fb2128_s=860x_.JPG"} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/19495/00696c8119524e6fb05f7acc70fb2128_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19495/00696c8119524e6fb05f7acc70fb2128_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19495/00696c8119524e6fb05f7acc70fb2128_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19495/00696c8119524e6fb05f7acc70fb2128_s=1400x_.JPG 1400w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/433ac607c3b340c48b99fdfac6023a31_s=860x_.JPG"} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/19495/433ac607c3b340c48b99fdfac6023a31_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19495/433ac607c3b340c48b99fdfac6023a31_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19495/433ac607c3b340c48b99fdfac6023a31_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19495/433ac607c3b340c48b99fdfac6023a31_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19495/433ac607c3b340c48b99fdfac6023a31_s=2000x_.JPG 2000w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"8qigv75095b"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/cd007129c334492282e2e0fa2657c9d9_s=860x_.JPG"} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/19495/cd007129c334492282e2e0fa2657c9d9_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19495/cd007129c334492282e2e0fa2657c9d9_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19495/cd007129c334492282e2e0fa2657c9d9_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19495/cd007129c334492282e2e0fa2657c9d9_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19495/cd007129c334492282e2e0fa2657c9d9_s=2000x_.JPG 2000w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/fc1e48bd9b24448ea6b3b9a2721595f2_s=860x_.JPG"} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/19495/fc1e48bd9b24448ea6b3b9a2721595f2_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19495/fc1e48bd9b24448ea6b3b9a2721595f2_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19495/fc1e48bd9b24448ea6b3b9a2721595f2_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19495/fc1e48bd9b24448ea6b3b9a2721595f2_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19495/fc1e48bd9b24448ea6b3b9a2721595f2_s=2000x_.JPG 2000w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/5ea9c0953ac14183b509c617aa03bad6_s=860x_.JPG"} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/19495/5ea9c0953ac14183b509c617aa03bad6_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19495/5ea9c0953ac14183b509c617aa03bad6_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19495/5ea9c0953ac14183b509c617aa03bad6_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19495/5ea9c0953ac14183b509c617aa03bad6_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19495/5ea9c0953ac14183b509c617aa03bad6_s=2000x_.JPG 2000w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/151bf40fc91647bcacd1a53c7d1bde2f_s=860x_.JPG"} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/19495/151bf40fc91647bcacd1a53c7d1bde2f_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19495/151bf40fc91647bcacd1a53c7d1bde2f_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19495/151bf40fc91647bcacd1a53c7d1bde2f_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19495/151bf40fc91647bcacd1a53c7d1bde2f_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19495/151bf40fc91647bcacd1a53c7d1bde2f_s=2000x_.JPG 2000w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--08 pt--60" name={"architect"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--48" content={"marek tůma.<br><span style=\"font-style: italic;\">the architect.</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":534}} content={"Member of The Czech Chamber of Architects<br>ČKA 04 680<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--06" name={"gwgdaeytjok"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/81fe84ced0fe4fc99cdbbc55737eca7a_s=860x_.jpg"} url={"https://www.archilovers.com/projects/245417/the-spojovna-brewery.html"} use={"url"} href={"https://www.archilovers.com/projects/245417/the-spojovna-brewery.html"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19495/81fe84ced0fe4fc99cdbbc55737eca7a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19495/81fe84ced0fe4fc99cdbbc55737eca7a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19495/81fe84ced0fe4fc99cdbbc55737eca7a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19495/81fe84ced0fe4fc99cdbbc55737eca7a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19495/81fe84ced0fe4fc99cdbbc55737eca7a_s=2000x_.jpg 2000w"} lightbox={false}>
              </Image>

              <Text className="text-box text-box--right fs--11 w--300 mt--06" content={"spojovna brewery&amp;restaurant, prague<br>photo: boysplaynice"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/bc454f5aae5a4fc8a9595c654c370be8_s=660x_.jpg"} url={"https://www.archilovers.com/projects/164520/nejen-bistro.html"} use={"url"} href={"https://www.archilovers.com/projects/164520/nejen-bistro.html"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19495/bc454f5aae5a4fc8a9595c654c370be8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19495/bc454f5aae5a4fc8a9595c654c370be8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19495/bc454f5aae5a4fc8a9595c654c370be8_s=860x_.jpg 860w"} lightbox={false}>
              </Image>

              <Text className="text-box text-box--right fs--11 w--300 mt--06" content={"nejen bistro, prague <br>photo: boysplaynice<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/4643639442a0404cb33a5df364002100_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19495/4643639442a0404cb33a5df364002100_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19495/4643639442a0404cb33a5df364002100_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19495/4643639442a0404cb33a5df364002100_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19495/4643639442a0404cb33a5df364002100_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box text-box--right fs--11 w--300 mt--06" content={"concept proposal<br>residential<br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19495/eb39d06b99864327b7df3427856c2948_e=0x0x2646x1489_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"paddingTop":67,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/19495/eb39d06b99864327b7df3427856c2948_e=0x0x2646x1489_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19495/eb39d06b99864327b7df3427856c2948_e=0x0x2646x1489_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19495/eb39d06b99864327b7df3427856c2948_e=0x0x2646x1489_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19495/eb39d06b99864327b7df3427856c2948_e=0x0x2646x1489_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19495/eb39d06b99864327b7df3427856c2948_e=0x0x2646x1489_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--11 w--300 mt--06" content={"family house proposal<br>archviz: monolot<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-g538bn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/c8bb3e2d709b48b280ae55034618dd33_ove=008cffx8__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/c8bb3e2d709b48b280ae55034618dd33_ove=008cffx8__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/c8bb3e2d709b48b280ae55034618dd33_ove=008cffx8__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/c8bb3e2d709b48b280ae55034618dd33_ove=008cffx8__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/c8bb3e2d709b48b280ae55034618dd33_ove=008cffx8__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/c8bb3e2d709b48b280ae55034618dd33_ove=008cffx8__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/c8bb3e2d709b48b280ae55034618dd33_ove=008cffx8__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19495/c8bb3e2d709b48b280ae55034618dd33_ove=008cffx8__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"}>
              
              <Title className="title-box fs--72" content={"Contact"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5" use={"page"} href={"/#paticka"} target={null} content={"info@marektuma.com"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366,"marginTop":0,"paddingTop":0}} content={"<font color=\"rgba(0,0,0,1)\">*1988<br>CTU in Prague<br><a href=\"https://www.cka.cz/cs/svet-architektury/seznam-architektu/ing-arch-tuma-marek\" target=\"_blank\">CCA member, ČKA 04 680</a><br></font>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--48" content={"<font color=\"rgba(0,0,0,1)\">marek tůma<br></font>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">+420 733 391 091<br><font color=\"rgba(0,0,0,1)\">info@marektuma.com<br>Prague, CZ</font></span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\"><font color=\"rgba(0,0,0,1)\">Vytvořeno přes <a href=\"https://saywebpage.com\">saywebpage.com</a></font></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}