import React from 'react'
import { ThemeProvider } from '@emotion/react'

const theme = {
  "id": 139,
  "colors": {
    "colorVariable1": "rgba(177,37,37,1)",
    "colorVariable2": "rgba(177,37,37,1)",
    "colorVariableBtn1": "rgba(255,252,251,1)",
    "colorVariableBtn2": "rgba(3,3,26,1)",
    "front1": "rgba(121,121,121,1)",
    "front2": "rgba(255,255,255,1)",
    "back2": "rgba(0,0,0,1)"
  },
  "variables": {
    "color-variable-1": "rgba(146,148,152,1)",
    "color-variable-2": "rgba(112,114,117,1)",
    "color-variable-btn-1": "rgba(21,21,21,1)",
    "color-variable-btn-2": "rgba(255,255,255,1)",
    "--btn-color": "var(--color-blend--05)",
    "--btn-hover-color": "var(--color-supplementary)",
    "--btn-text-color": "var(--color-custom-1--15)",
    "--link-color": "var(--black)",
    "--link-hover-color": "var(--black)",
    "--selection-color": "var(--black)",
    "--text-color": "var(--color-blend--15)"
  },
  "globalMobile": "27em",
  "meta": []
}

export default function ThemeWrapper(props) {
  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  )
}